var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cz-dialog',_vm._g(_vm._b({attrs:{"width":1300,"max-width":'90%',"title":_vm.$t('customer.vendorSubmissions.submissionHistory')}},'cz-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-timeline',{staticClass:"pa-10",attrs:{"reverse":"","dense":_vm.$vuetify.breakpoint.smAndDown}},[_vm._l((_vm.items),function(item){return [(item.approved)?_c('v-timeline-item',{key:`${item._id}_approved`,attrs:{"color":"positive","icon":_vm.mdiFileDocumentCheckOutline,"icon-color":"white","large":""}},[_c('v-card',{attrs:{"color":"white"}},[_c('v-card-text',{staticClass:"d-flex flex-column"},[(item.user)?_c('div',{staticClass:"font-weight-semibold text-title textPrimary--text",domProps:{"innerHTML":_vm._s(
                _vm.$t('customer.vendorSubmissions.approvedBy', [
                  item.user?.fullName
                ])
              )}}):_vm._e()])],1)],1):_vm._e(),_c('v-timeline-item',{key:item._id,attrs:{"color":_vm.getStatusColor(item.status),"icon":_vm.getStatusIcon(item.status),"icon-color":"white","large":""}},[_c('v-card',{attrs:{"color":"white"}},[_c('v-card-text',{staticClass:"d-flex flex-column",staticStyle:{"row-gap":"10px"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"column-gap":"3px"}},[_c('div',{staticClass:"font-weight-semibold text-title textPrimary--text"},[_vm._v(" "+_vm._s(item.statusText)+" ")]),(
                  item.status === _vm.OrderSubmissionStatus.RETURNED ||
                  item.status === _vm.OrderSubmissionStatus.REJECTED ||
                  !!item.note
                )?_c('cz-menu-button',{attrs:{"button-icon-size":22,"menu-max-width":250,"flat":"","color":"primary","button-icon-src":_vm.mdiInformationOutline,"menuItems":_vm.getStatusMenuItems(item)}}):_vm._e()],1),_c('div',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.formatDateTimeToDisplay(item.updatedAt))+" ")]),_c('div',{staticClass:"d-flex align-center"},[(item.userId === 'automation-user')?_c('img',{attrs:{"src":_vm.milo,"height":30,"width":40}}):_vm._e(),_c('span',{staticClass:"text-subtitle-2 textPrimary--text",domProps:{"innerHTML":_vm._s(
                  _vm.$t(
                    'customer.vendorSubmissions.submissionHistoryCreatedBy',
                    [
                      item.approverUser
                        ? item.approverUser.fullName
                        : item.user.fullName
                    ]
                  )
                )}})])])],1)],1)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }