<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :width="1300"
    :max-width="'90%'"
    :title="$t('customer.vendorSubmissions.submissionHistory')"
  >
    <v-timeline reverse :dense="$vuetify.breakpoint.smAndDown" class="pa-10">
      <template v-for="item in items">
        <v-timeline-item
          v-if="item.approved"
          :key="`${item._id}_approved`"
          color="positive"
          :icon="mdiFileDocumentCheckOutline"
          icon-color="white"
          large
        >
          <v-card color="white">
            <v-card-text class="d-flex flex-column">
              <div
                class="font-weight-semibold text-title textPrimary--text"
                v-if="item.user"
                v-html="
                  $t('customer.vendorSubmissions.approvedBy', [
                    item.user?.fullName
                  ])
                "
              />
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item
          :key="item._id"
          :color="getStatusColor(item.status)"
          :icon="getStatusIcon(item.status)"
          icon-color="white"
          large
        >
          <v-card color="white">
            <v-card-text class="d-flex flex-column" style="row-gap: 10px">
              <div class="d-flex align-center" style="column-gap: 3px">
                <div class="font-weight-semibold text-title textPrimary--text">
                  {{ item.statusText }}
                </div>
                <cz-menu-button
                  v-if="
                    item.status === OrderSubmissionStatus.RETURNED ||
                    item.status === OrderSubmissionStatus.REJECTED ||
                    !!item.note
                  "
                  :button-icon-size="22"
                  :menu-max-width="250"
                  flat
                  color="primary"
                  :button-icon-src="mdiInformationOutline"
                  :menuItems="getStatusMenuItems(item)"
                />
              </div>

              <div class="text-body-1">
                {{ formatDateTimeToDisplay(item.updatedAt) }}
              </div>
              <div class="d-flex align-center">
                <img
                  :src="milo"
                  :height="30"
                  :width="40"
                  v-if="item.userId === 'automation-user'"
                />
                <span
                  class="text-subtitle-2 textPrimary--text"
                  v-html="
                    $t(
                      'customer.vendorSubmissions.submissionHistoryCreatedBy',
                      [
                        item.approverUser
                          ? item.approverUser.fullName
                          : item.user.fullName
                      ]
                    )
                  "
                />
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </template>
    </v-timeline>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzMenuButton } from '@/components';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import milo from '@/assets/milo.svg';
import {
  mdiFileDocumentPlusOutline,
  mdiFileDocumentCheckOutline,
  mdiFileDocumentArrowRightOutline,
  mdiFileDocumentAlertOutline,
  mdiAccountClockOutline,
  mdiInformation,
  mdiInformationOutline
} from '@mdi/js';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
export default {
  name: 'SubmssionHistoryDialog',
  components: {
    CzDialog,
    CzMenuButton
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      milo,
      mdiFileDocumentAlertOutline,
      mdiFileDocumentCheckOutline,
      mdiInformation,
      mdiInformationOutline,
      OrderSubmissionStatus
    };
  },
  methods: {
    formatDateTimeToDisplay,
    getStatusColor(status) {
      switch (status) {
        case OrderSubmissionStatus.APPROVED:
          return 'positive';
        case OrderSubmissionStatus.REJECTED:
          return 'negative';
        case OrderSubmissionStatus.NEW:
        case OrderSubmissionStatus.NEW_PREDICTED:
          return 'primary darken-1';
        case OrderSubmissionStatus.RETURNED:
          return 'primary darken-1';
        case OrderSubmissionStatus.PENDING_AUTHORIZER:
        case OrderSubmissionStatus.PENDING_PURCHASER:
          return 'primary darken-1';
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case OrderSubmissionStatus.APPROVED:
          return mdiFileDocumentCheckOutline;
        case OrderSubmissionStatus.REJECTED:
          return mdiFileDocumentAlertOutline;
        case OrderSubmissionStatus.NEW:
        case OrderSubmissionStatus.NEW_PREDICTED:
          return mdiFileDocumentPlusOutline;
        case OrderSubmissionStatus.RETURNED:
          return mdiFileDocumentArrowRightOutline;
        case OrderSubmissionStatus.PENDING_APPROVAL:
          return mdiAccountClockOutline;
      }
    },
    getStatusMenuItems(item) {
      // customer.vendorSubmissions.returnType'
      switch (item.status) {
        case OrderSubmissionStatus.RETURNED:
          return [
            {
              id: 'return-type',
              title: this.$t('customer.vendorSubmissions.returnType'),
              subtitle: item.returnTypeText
            },
            {
              id: 'note',
              title: this.$t('common.note'),
              subtitle: item.note
            }
          ];
        case OrderSubmissionStatus.REJECTED:
          return [
            {
              id: 'return-type',
              title: this.$t('customer.vendorSubmissions.rejectType'),
              subtitle: item.rejectTypeText
            },
            {
              id: 'note',
              title: this.$t('common.note'),
              subtitle: item.note
            }
          ];
        case OrderSubmissionStatus.APPROVED:
          if (item.note) {
            return [
              {
                id: 'note',
                title: this.$t('common.note'),
                subtitle: item.note
              }
            ];
          } else {
            return [];
          }
        default:
          if (item.note) {
            return [
              {
                id: 'note',
                title: this.$t('common.note'),
                subtitle: item.note
              }
            ];
          } else {
            return [];
          }
      }
    }
  }
};
</script>

<style></style>
